import React, { createContext, useState, useContext, useEffect } from 'react';

// Create User Context
const UserContext = createContext();

// Provide User Context
export const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('userId') !== null) {
      setIsLoggedIn(true)
    }
  }, []);
  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom Hook to use UserContext
export const useUser = () => useContext(UserContext);
