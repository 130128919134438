import classes from './Favourites.module.css';
import SideMenu from './SideMenu';

const Favourites = () => {
  return (
    <SideMenu>
      <div className={classes.fav}>
        <h1 className={classes.fav_head}>Favourites</h1>
        <ul className={classes.fav_list}>
          {[1, 2, 3, 4, 5].map((item) => (
            <li key={item} className={classes.fav_list_item}>
              <p className={classes.fav_list_item_tag}>Online</p>
              <svg className={classes.fav_list_item_svg}>
                <use href="/assets/svgs/stripe.svg#delete"></use>
              </svg>
              <img
                className={classes.fav_list_item_img}
                src={`/assets/images/popular/d${item}.jpg`}
                alt="popular"
              />
              <div className={classes.fav_list_brief}>
                <p className={classes.fav_list_title}>United Kingdom</p>
                <p className={classes.fav_list_price}>INR 5000/-</p>
              </div>
              <ul className={classes.fav_list_item_info}>
                <li className={classes.fav_list_item_info_li}>
                  <p>Processing time</p>
                  <p>Upto 5 days</p>
                </li>
                <li className={classes.fav_list_item_info_li}>
                  <p>Stay period</p>
                  <p>2 days</p>
                </li>
                <li className={classes.fav_list_item_info_li}>
                  <p>Validity</p>
                  <p>30 days</p>
                </li>
                <li className={classes.fav_list_item_info_li}>
                  <p>Entry</p>
                  <p>Single</p>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </SideMenu>
  );
};
export default Favourites;
