import { Link, useNavigate } from 'react-router-dom';
import classes from './VisaHome.module.css';
import SearchHeader from '../header/SearchHeader';
import { useEffect, useState } from 'react';
import visasdata from '../../data/singlevisas.json'
const VisaHome = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visas, setVisas] = useState([]);
  const headerconfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    }
  }
  useEffect(() => {
    //GetVisas();
    setVisas(visasdata)
  }, []);
  const itemHandle = (e, visaId) => {
    e.preventDefault();
    navigate('/visa/' + visaId);
  }
  return (
    <>
      <SearchHeader></SearchHeader>
      <div className={classes.vh}>
        <ul className={classes.vh_list}>
          {visas.length > 0 && visas.map((item) => (
            <li key={item} className={classes.vh_list_item}>
              <p className={classes.vh_list_item_tag}>{item.visaCountryName}</p>
              <svg className={classes.vh_list_item_svg}>
                <use href="/assets/svgs/stripe.svg#heartFull"></use>
              </svg>
              <img
                className={classes.vh_list_item_img}
                src={"/assets/images/visacountries/" + item.flagSrc + ".png"}
                alt="popular" style={{maxWidth:"400px", maxHeight:"150px"}}
              />
              <Link to={"visa/"+item.visaId}>
              <div className={classes.vh_list_brief}>
                <p className={classes.vh_list_title}>{item.visaName}</p>
                <p className={classes.vh_list_price}>INR {item.adultPrice}/-</p>
              </div>
              <ul className={classes.vh_list_item_info}>
                <li className={classes.vh_list_item_info_li}>
                  <p>Processing time</p>
                  <p>Upto {item.processingTime == 1 ? "24 hours" : item.processingTime + " days"}</p>
                </li>
                <li className={classes.vh_list_item_info_li}>
                  <p>Stay period</p>
                  <p>{item.stayPeriod}</p>
                </li>
                <li className={classes.vh_list_item_info_li}>
                  <p>Validity</p>
                  <p>{item.validity}</p>
                </li>
                <li className={classes.vh_list_item_info_li}>
                  <p>Entry</p>
                  <p>{item.entry}</p>
                </li>
              </ul>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default VisaHome;
