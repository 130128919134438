import { useState } from 'react';
import classes from './Footer.module.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [toggleInput, setToggleInput] = useState(false);

  const inputToggle = () => setToggleInput(!toggleInput);

  return (
    <div className={classes.footer}>
      <div className={classes.footer_help}>
        <svg className={classes.footer_help_svg} onClick={inputToggle}>
          <use href="/assets/svgs/stripe.svg#call_fill"></use>
        </svg>
        {toggleInput && (
          <div className={classes.footer_help_data}>
            <h1 className={classes.footer_help_data_h}>Need Assistance?</h1>
            <ul className={classes.footer_help_data_ul}>
              <li>
                <p>Email:</p>
                <p>help@trawtel.com</p>
              </li>
              <li>
                <p>Call:</p>
                <p>+91 9876543210</p>
              </li>
              <p className={classes.footer_help_data_msg}>
                09:00AM - 09:00PM (working hours)
              </p>
            </ul>

            <p className={classes.footer_help_data_msg2}>
              Feel free to connect with us. Make enquiries or get assistance.
            </p>
          </div>
        )}
      </div>
      <div className={classes.footer_section}>
        <div className={classes.footer_col1}>
          <div className={classes.footer_col1_row1}>
            <p className={classes.footer_logo}>Trawtel</p>
            <p className={classes.footer_tag}>Built for India</p>
          </div>
          <p className={classes.footer_col1_row2}>
            Paragraphs Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
            turpis. Suspendisse urna nibh viverra non semper suscipit posuere a
            pede.
          </p>
          <div className={classes.footer_col1_row3}>
            <Link to="www.instagram.com">
              <img
                className={classes.footer_social}
                src="/assets/images/insta.png"
                alt="instagram"
              />
            </Link>
            <Link to="www.facebook.com">
              <img
                className={classes.footer_social}
                src="/assets/images/facebook.png"
                alt="facebook"
              />
            </Link>
            <Link to="www.whatsapp.com">
              <img
                className={classes.footer_social}
                src="/assets/images/whatsapp.png"
                alt="whatsapp"
              />
            </Link>
          </div>
        </div>
        <div className={classes.footer_col2}>
          <h4 className={classes.footer_link_h}>Company</h4>
          <div className={classes.footer_link_list}>
            <Link>For Travel Agents</Link>
            <Link to="/about">About Us</Link>
            <Link>Jobs</Link>
            <Link>Reviews</Link>
            <Link>Partners</Link>
          </div>
        </div>
        <div className={classes.footer_col3}>
          <h4 className={classes.footer_link_h}>Support</h4>
          <div className={classes.footer_link_list}>
            <Link>Help Center</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className={classes.footer_col4}>
          <h4 className={classes.footer_link_h}>Legal</h4>
          <div className={classes.footer_link_list}>
            <Link to="/privacy">Privacy</Link>
            <Link>Terms</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
