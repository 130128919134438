import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classes from './ApplicationForm.module.css';
import SideMenu from './SideMenu';
import moment from 'moment';
import axios from 'axios';
import { VisaApplicantUrls, VisaRequestUrls } from '../common/APIConfig';
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";
import { FaCalendarAlt, FaArchive } from "react-icons/fa";
import visaData from "../../data/b2cvisas.json";
const initialFieldValues = {
  visaApplicantId: "00000000-0000-0000-0000-000000000000",
  visaRequestId: "00000000-0000-0000-0000-000000000000",
  firstName: "",
  lastName: "",
  title: "Mr.",
  gender: 'MALE',
  visaApplicantStatus: "NEW",
  dateOfBirth: new Date(),
  passportNumber: "",
  passportIssueDate: new Date(),
  passportExpireDate: new Date(),
  travelerType: 'ADULT',
  nationality: 'INDIAN',
  passportCountry: 'INDIA',
  profileFile: null,
  passportFrontFile: null,
  passportBackFile: null,
  profileSrc: '/assets/images/avatar.png',
  passportFrontSrc: '/assets/images/doc_bgc.png',
  passportBackSrc: '/assets/images/doc_bgc.png',
  comments: 0
};
const ApplicationForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [showPhotoUpload, setShowPhotoUpload] = useState(true);
  const [showPassportUpload, setShowPassportUpload] = useState(false);
  const [showFormUpload, setShowFormUpload] = useState(false);
  const [showApplicantPreview, setShowApplicantPreview] = useState(false);
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});
  const [visaRequest, setVisaRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [visa, setVisa] = useState({})
  const [visas, setVisas] = useState({})
  const [visaCountry, setVisaCountry] = useState({})
  const [visaDocuments, setVisaDocuments] = useState([])
  const [visaTerms, setVisaTerms] = useState([])
  const [visaFAQ, setVisaFAQ] = useState([])
  const [files, setFiles] = useState([]);
  const photographHandle = () => {
    setShowPhotoUpload(true);
    setShowFormUpload(false);
    setShowPassportUpload(false);
    setShowApplicantPreview(false);
  };
  const passportHandle = () => {
    setShowPhotoUpload(false);
    setShowFormUpload(false);
    setShowPassportUpload(true);
    setShowApplicantPreview(false);
  };
  const formHandle = () => {
    setShowPhotoUpload(false);
    setShowFormUpload(true);
    setShowPassportUpload(false);
    setShowApplicantPreview(false);
  };
  const viewHandle = () => {
    if (validate()) {
      setShowPhotoUpload(false);
      setShowFormUpload(false);
      setShowPassportUpload(false);
      setShowApplicantPreview(true);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const headerconfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken")
    }
  }
  const showProfilePreview = e => {
    if (e.target.files && e.target.files[0]) {
      let profileFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = x => {
        setValues({
          ...values,
          profileFile,
          profileSrc: x.target.result
        })
      }
      reader.readAsDataURL(profileFile)
    }
    else {
      setValues({
        ...values,
        profileFile: null,
        profileSrc: ""
      })
    }
  }
  const showPassportFrontPreview = e => {
    if (e.target.files && e.target.files[0]) {
      let passportFrontFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = x => {
        setValues({
          ...values,
          passportFrontFile,
          passportFrontSrc: x.target.result
        })
      }
      reader.readAsDataURL(passportFrontFile)
    }
    else {
      setValues({
        ...values,
        passportFrontFile: null,
        passportFrontSrc: ""
      })
    }
  }
  const showPassportBackPreview = e => {
    if (e.target.files && e.target.files[0]) {
      let passportBackFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = x => {
        setValues({
          ...values,
          passportBackFile,
          passportBackSrc: x.target.result
        })
      }
      reader.readAsDataURL(passportBackFile)
    }
    else {
      setValues({
        ...values,
        passportBackFile: null,
        passportBackSrc: ""
      })
    }
  }
  const validate = () => {
    console.log(values.passportExpireDate)
    let temp = {};
    temp.firstName = values.firstName === "" ? false : true;
    temp.lastName = values.lastName === "" ? false : true;
    temp.passportNumber = values.passportNumber === "" ? false : true;
    temp.dateOfBirth = values.dateOfBirth === undefined ? false : true;
    temp.passportIssueDate = values.passportIssueDate === undefined ? false : true;
    temp.passportExpireDate = values.passportExpireDate === undefined ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x === true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      const formData = new FormData()
      formData.append("visaApplicantId", values.visaApplicantId)
      formData.append("visaRequestId", params.visaRequestId)
      formData.append("firstName", values.firstName)
      formData.append("lastName", values.lastName)
      formData.append("title", values.title)
      formData.append("gender", values.gender)
      formData.append("visaApplicantStatus", visaRequest.visaRequestStatus)
      formData.append("dateOfBirth", moment(values.dateOfBirth).format('YYYY-MM-DD'))
      formData.append("passportIssueDate", moment(values.passportIssueDate).format('YYYY-MM-DD'))
      formData.append("passportExpireDate", moment(values.passportExpireDate).format('YYYY-MM-DD'))
      formData.append("passportNumber", values.passportNumber)
      formData.append("travelerType", values.travelerType)
      formData.append("nationality", values.nationality)
      formData.append("passportCountry", values.passportCountry)
      formData.append("profileFile", values.profileFile)
      formData.append("passportFrontFile", values.passportFrontFile)
      formData.append("passportBackFile", values.passportBackFile)
      formData.append("comments", values.comments)
      Array.from(files).forEach((file) => {
        formData.append("files", file);
      });
      axios.post(VisaApplicantUrls.createVisaApplicant, formData, { ...headerconfig })
        .then((res) => {
          if (res.data.statusCode == 200) {
            setLoading(false);
            navigate("/visaapplicants/" + params.visaRequestId)
          }
        }).catch(function (error) {
          setLoading(false);
        });
    }
    else {
      alert('check mandatory fields');
      setLoading(false);
    }
  };
  // Handle file input change
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to an array
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  // Remove a file from the list
  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Change a specific file
  const handleChangeFile = (index, event) => {
    const updatedFile = event.target.files[0]; // Get the new file
    if (updatedFile) {
      setFiles((prevFiles) =>
        prevFiles.map((file, i) => (i === index ? updatedFile : file))
      );
    }
  };

  const getVisaRequest = () => {
    axios.get(VisaRequestUrls.getVisaRequestsById + "/" + params.visaRequestId, { ...headerconfig })
      .then((res) => {
        if (res.data.data !== null) {
          if (res.data.data.visaRequestStatus === "SUBMITTED") {
            navigate("/visaapplicants/" + params.visaRequestId)
          }
          else {
            setVisaRequest(res.data.data);
            getCountryByVisaId(res.data.data.visaId)
          }
        }
      })
  }
  function getCountryByVisaId(visaId) {
    if (!visaId) {
      console.error("No visaId provided in params");
      return;
    }
    // Find the country that contains the visaId in its visas array
    const matchingCountry = visaData.find((country) =>
      country.visas?.some((visa) => visa.visaId === visaId)
    );

    if (matchingCountry) {
      setVisaCountry(matchingCountry);
      setVisas(matchingCountry.visas);
      const matchingVisa = matchingCountry.visas.find((v) => v.visaId === visaId)
      setVisa(matchingVisa)
      setVisaDocuments(matchingCountry.visaDocuments)
      setVisaFAQ(matchingCountry.visaFAQs)
      setVisaTerms(matchingCountry.visaTerms)
    } else {
      console.warn("No country found for visaId:", visaId);
    }
  };
  useEffect(() => {
    getVisaRequest();
  }, []);
  return (
    <SideMenu>
      <div className={classes.va}>
        <h3 className={classes.va_heading}>Application for {visaRequest?.visaName}</h3>

        {/* side buttons */}
        <div className={classes.va_nav}>
          <div className={showPhotoUpload ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}` : classes.va_nav_btns} onClick={photographHandle}>
            <svg><use href="/assets/svgs/stripe.svg#photo"></use></svg>
          </div>
          <div className={showPassportUpload ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}` : classes.va_nav_btns} onClick={passportHandle}>
            <svg>
              <use href="/assets/svgs/stripe.svg#passport"></use>
            </svg>
          </div>
          <div className={showFormUpload ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}` : classes.va_nav_btns} onClick={formHandle}>
            <svg>
              <use href="/assets/svgs/stripe.svg#form"></use>
            </svg>
          </div>
          <div className={showApplicantPreview ? `${classes.va_nav_btnsActive} ${classes.va_nav_btns}` : classes.va_nav_btns} onClick={viewHandle}>
            <svg>
              <use href="/assets/svgs/stripe.svg#view"></use>
            </svg>
          </div>
        </div>
        {/* photograph */}
        {showPhotoUpload && (
          <div className={classes.va_photo}>
            <h1 className={classes.va_photo_head}>
              Photograph (Passport-size)
            </h1>
            <img className={classes.va_photo_img} src={values.profileSrc} alt="photograph passport-size" />
            <ul className={classes.va_photo_guide}>
              <li>A recent coloured photograph</li>
              <li>Set the colour to true Colour.</li>
              <li>The image file should be .jpg/ .jpeg format.</li>
              <li>Size of file should be between 50 KB-100 KB</li>
            </ul>
            <input className={classes.va_photo_input} type="file" name='profileSrc' onChange={showProfilePreview}></input>
            <button className={classes.va_photo_upldBtn} onClick={passportHandle}>Next</button>
          </div>
        )}

        {/* passport photo */}
        {showPassportUpload && (
          <div className={classes.va_passport}>
            <h1 className={classes.va_passport_head}>
              Passport scanned images
            </h1>
            <div className={classes.va_passport_select}>
              <img className={classes.va_passport_img} src={values.passportFrontSrc} alt="photograph passport-size" />
              <ul className={classes.va_passport_guide}>
                <li>Set the scanner resolution to a minimum of 200 dpi (dots per inch).</li>
                <li>Set the colour to true Colour.</li>
                <li>The image file should be .jpg/ .jpeg format.</li>
                <li>Size of file should be between 100 KB-250 KB</li>
              </ul>

              <input className={classes.va_passport_input} type="file" onChange={showPassportFrontPreview} name='passportFrontSrc'></input>
            </div>
            <div className={classes.va_passport_select}>
              <img className={classes.va_passport_img} src={values.passportBackSrc} alt="photograph passport-size" />
              <ul className={classes.va_passport_guide}>
                <li>Set the scanner resolution to a minimum of 200 dpi (dots per inch).</li>
                <li>Set the colour to true Colour.</li>
                <li>The image file should be .jpg/ .jpeg format.</li>
                <li>Size of file should be between 100 KB-250 KB</li>
              </ul>

              <input className={classes.va_passport_input} type="file" onChange={showPassportBackPreview} name='passportBackSrc'></input>
            </div>
            <button className={classes.va_passport_upldBtn} onClick={formHandle}>Next</button>
          </div>
        )}

        {/* applicant form */}
        {showFormUpload && (
          <div className={classes.va_applicant}>
            <h1 className={classes.va_applicant_head}>APPLICANT DETAILS (Please fill as per passport)</h1>
            <div className={classes.va_applicant_form}>
              <div className={classes.va_applicant_inputDiv}>
                <label>PASSPORT NUMBER</label>
                <input type="text" name="passportNumber" placeholder="Please enter passport no." value={values.passportNumber} onChange={handleInputChange} maxLength={9}></input>
                {errors.passportNumber === false ? (<div className="validationerror"> Please enter passport no </div>) : ('')}
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>FIRST NAME</label>
                <input type="text" name="firstName" placeholder="Please enter first name" value={values.firstName} onChange={handleInputChange}></input>
                {errors.firstName === false ? (<div className="validationerror"> Please enter first name </div>) : ('')}
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>LAST NAME</label>
                <input type="text" name="lastName" placeholder="Please enter last name" value={values.lastName} onChange={handleInputChange}></input>
                {errors.lastName === false ? (<div className="validationerror"> Please enter last name </div>) : ('')}
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>GENDER</label>
                <select name="gender" value={values.gender} onChange={handleInputChange}>
                  <option value={"MALE"}>Male</option>
                  <option value={"FEMALE"}>Female</option>
                  <option value={"OTHER"}>Other</option>
                </select>
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>DATE OF BIRTH</label>
                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <Flatpickr value={values.dateOfBirth} options={{ maxDate: new Date(), dateFormat: "d-m-Y" }} onChange={([date]) => { setValues({ ...values, ['dateOfBirth']: date }); }} />
                  <button type="button" onClick={() => document.querySelector(".flatpickr-input")._flatpickr.open()} style={{ position: "absolute", right: "10px", background: "none", border: "none", cursor: "pointer", }}>
                    <FaCalendarAlt size={20} color="#FDE047" />
                  </button>
                </div>
                {errors.dateOfBirth === false ? (<div className="validationerror"> Please enter date of birth </div>) : ('')}
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>PASSPORT ISSUE DATE</label>
                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <Flatpickr value={values.passportIssueDate} options={{ maxDate: new Date(), dateFormat: "d-m-Y" }} onChange={([date]) => { setValues({ ...values, ['passportIssueDate']: date }); }} />
                  <button type="button" onClick={() => document.querySelector(".flatpickr-input")._flatpickr.open()} style={{ position: "absolute", right: "10px", background: "none", border: "none", cursor: "pointer", }}>
                    <FaCalendarAlt size={20} color="#FDE047" />
                  </button>
                </div>
                {errors.dateOfBirth === false ? (<div className="validationerror"> Please enter passport issue date </div>) : ('')}
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>PASSPORT EXPIRY DATE</label>
                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <Flatpickr value={values.passportExpireDate} options={{ dateFormat: "d-m-Y" }} onChange={([date]) => { setValues({ ...values, ['passportExpireDate']: date }); }} />
                  <button type="button" onClick={() => document.querySelector(".flatpickr-input")._flatpickr.open()} style={{ position: "absolute", right: "10px", background: "none", border: "none", cursor: "pointer", }}>
                    <FaCalendarAlt size={20} color="#FDE047" />
                  </button>
                </div>
                {errors.passportExpireDate === false ? (<div className="validationerror"> Please enter passport expire date </div>) : ('')}
              </div>
              <div className={classes.va_applicant_inputDiv}>
                <label>TRAVELER CATEGORY</label>
                <select name="travelerType" value={values.travelerType} onChange={handleInputChange}>
                  <option value={"ADULT"}>Adult (18+ yrs)</option>
                  <option value={"CHILD"}>Child (3- 18 yrs)</option>
                  <option value={"INFANT"}>Infant (0 - 2 yrs)</option>
                </select>
              </div>
            </div>
            <div className={classes.va_applicant_doc}>
              <div className="line mt-0 mb-5" />
              <h2 className="text-30">Documents Required (Only ".jpg,.jpeg,.pdf,.png" files accepted)</h2>
              <div className="row x-gap-130 y-gap-20 pt-20">
                <div className="col-lg-12">
                  <div className="y-gap-15">
                    {visaDocuments.length > 0 && visaDocuments.map(vd =>
                      <div className="d-flex">
                        <i className="icon-check flex-center text-10 size-24 rounded-full text-green-2 bg-green-1 mr-15" />
                        {vd.documentName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <input className={classes.va_applicant_docInput} type="file" multiple onChange={handleFileChange} accept=".jpg,.jpeg,.pdf,.png"/>
            </div>
            <div>
              <h3>Selected Files:</h3>
              {files.length > 0 ? (
                <ul>
                  {files.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <button onClick={() => handleRemoveFile(index)} style={{ padding: "10px" }} title='Delete'><FaArchive size={20} color="#FF0000" /></button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No files selected.</p>
              )}
            </div>
            <div className={classes.va_applicant_form}>
              <div className={classes.va_applicant_inputDiv}>
                <label>&nbsp;</label>
                <button className={classes.va_applicant_saveBtn} onClick={viewHandle}>Preview</button>
              </div>
            </div>

          </div>
        )}

        {/* View all details */}
        {showApplicantPreview && (
          <div className={classes.va_preview}>
            <h1 className={classes.va_preview_h}>Applicant details preview</h1>
            <div className={classes.va_preview_images}>
              <div className={classes.va_preview_imagesDiv}>
                <svg className={classes.va_preview_imagesDiv_edit} onClick={photographHandle}>
                  <use href="/assets/svgs/stripe.svg#edit"></use>
                </svg>
                <img alt="photograph passport-size" src={values.profileSrc} />
                <p>Photograph of passport size</p>
              </div>
              <div className={classes.va_preview_imagesDiv}>
                <svg className={classes.va_preview_imagesDiv_edit} onClick={passportHandle}>
                  <use href="/assets/svgs/stripe.svg#edit"></use>
                </svg>
                <img src={values.passportFrontSrc} alt="photograph passport-size" />
                <p>Scanned image of front page of passport</p>
              </div>
              <div className={classes.va_preview_imagesDiv}>
                <svg className={classes.va_preview_imagesDiv_edit} onClick={passportHandle}>
                  <use href="/assets/svgs/stripe.svg#edit"></use>
                </svg>
                <img src={values.passportBackSrc} alt="photograph passport-size" />
                <p>Scanned image of back page of passport</p>
              </div>
            </div>
            <div className={classes.va_preview_info}>
              <div className={classes.va_preview_info_div}>
                <p>Name</p>
                <p>{values.title} {values.firstName} {values.lastName}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Date of birth</p>
                <p>{moment(values.dateOfBirth).format('DD MMM YYYY')}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport number</p>
                <p>{values.passportNumber}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport issue date</p>
                <p>{moment(values.passportIssueDate).format('DD MMM YYYY')}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport expiry date</p>
                <p>{moment(values.passportExpireDate).format('DD MMM YYYY')}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Gender</p>
                <p>{values.gender}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Nationality</p>
                <p>{values.nationality}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Passport country</p>
                <p>{values.passportCountry}</p>
              </div>
              <div className={classes.va_preview_info_div}>
                <p>Traveler category</p>
                <p>{values.travelerType}</p>
              </div>
            </div>
            <div>
              <h3>Selected Files:</h3>
              {files.length > 0 ? (
                <ul>
                  {files.map((file, index) => (
                    <li key={index}>
                      {file.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No files selected.</p>
              )}
            </div>
            <div className={classes.va_preview_btns}>
              {loading === false ?
                <button className={classes.va_preview_btn} onClick={(e) => handleSubmit(e)}>
                  <span className="material-symbols-outlined"></span>Submit</button>
                :
                <button className={classes.va_preview_btn} disabled>
                  <span className="material-symbols-outlined"></span>Loading...</button>
              }
              <button className={classes.va_preview_btn} onClick={formHandle}>
                <span className="material-symbols-outlined"></span>Edit</button>
            </div>
          </div>
        )}
      </div>
    </SideMenu>
  );
};
export default ApplicationForm;
