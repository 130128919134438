import { NavLink, useNavigate } from 'react-router-dom';
import classes from './Header.module.css';
import countries from '../../data/singlevisas.json';
import { useRef, useState } from 'react';
const SearchHeader = () => {
  const [countryResults, setCountryResults] = useState([]);
  const [visaId, setVisaId] = useState("00000000-0000-0000-0000-000000000000");
  const [countryName, setCountryName] = useState("");
  const inputDRef = useRef(null);
  const navigate = useNavigate();
  const handleCountrySearch = (e) => {
    const value = e.target.value;
    setCountryName(value);
    if (value.length > 0) {
      const filteredAirports = countries
        .filter(airport =>
          airport.visaCountryName.toLowerCase().includes(value.toLowerCase())
        )
        .slice(0, 10);
      setCountryResults(filteredAirports);
    } else {
      setCountryResults([]);
    }
  };
  const handleCountryClick = (e) => {
    let filteredAirports = countries.filter(function (p) {
    }).slice(0, 10);
    setCountryResults(filteredAirports);
    inputDRef.current.select();    
  };
  const handleCountrySelect = (data) => {
    setVisaId(data.visaId);
    setCountryResults([]);
    navigate('/visa/' + data.visaId);
  }
  return (
    <searchheader className={classes.header}>
      <div className={classes.header_search_bar}>
        <svg className={classes.header_searchIcon}>
          <use href="/assets/svgs/stripe.svg#search"></use>
        </svg>
        <input ref={inputDRef} className={classes.header_search_input} type="text" placeholder="Search Destination" value={countryName} onChange={handleCountrySearch} onClick={handleCountryClick} onFocus={handleCountryClick} />        
        <div className={classes.header_search_fliterIconBox}>
          <svg className={classes.header_search_fliterIcon}>
            <use href="/assets/svgs/stripe.svg#filter"></use>
          </svg>
        </div>        
      </div>
      {countryResults.length > 0 && (
          <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px" }}>
            {countryResults.map((airport) => (
              <li className="locationListVideoItem" key={airport.visaId} onClick={() => handleCountrySelect(airport)}>
                <div className="item-name">{airport.visaCountryName}</div>
              </li>
            ))}
          </ul>
        )}        
    </searchheader>
  );
};
export default SearchHeader;
