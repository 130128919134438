import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classes from './ApplicationDetails.module.css';
import SideMenu from './SideMenu';
import moment from 'moment';
import axios from 'axios';
import { B2CGST, B2CServiceFee, PaymentUrls, VisaApplicantUrls, VisaRequestUrls } from '../common/APIConfig';
const ApplicationDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [visaRequest, setVisaRequest] = useState({});
    const [visaApplicants, setVisaApplicants] = useState([]);
    const [visaApplicantDocuments, setVisaApplicantDocuments] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);
    const [gst, setGST] = useState(0);
    const [netPrice, setNetPrice] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const handlePayment = (e) => {
        e.preventDefault();
        displayRazorpay();
    }
    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const result = await axios.post(
            PaymentUrls.PAYMENTREQUESTORDER + "?Amount=" + Math.round(parseFloat(visaRequest?.totalPrice)) + "&PayRequest=RAZORPAY"
        );

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        // Getting the order details back
        console.log(result.data)
        if (result.data.statusCode == 200) {
            const { amount, id: order_id, currency, razorpayKey, name } = result.data.response;
            const options = {
                key: razorpayKey, // Enter the Key ID generated from the Dashboard
                amount: amount,
                currency: currency,
                name: name,
                description: "Visa Booking Fee",
                order_id: order_id,
                handler: async function (response) {
                    console.log(response);
                    UpdateVisaRequests(response.razorpay_payment_id);
                },
                prefill: {
                    name: visaRequest?.firstName + " " + visaRequest.lastName,
                    email: "stanisha@developerscode.com",
                    contact: "9123456789",
                },
                notes: {
                    address: "Hyderabad",
                },
                theme: {
                    color: "#FDE047",
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert("Payment failed. Please try again");
        }
    }
    const UpdateVisaRequests = (RazorKey) => {
        const formData = new FormData()
        formData.append("visaRequestId", params.visaRequestId)
        formData.append("visaId", visaRequest.visaId)
        formData.append("visaName", visaRequest.visaName)
        formData.append("countryName", visaRequest.countryName)
        formData.append("pricePerApplicant", parseFloat(visaRequest.pricePerApplicant))
        formData.append("netPrice", parseFloat(netPrice))
        formData.append("serviceFee", parseFloat(serviceFee))
        formData.append("gst", parseFloat(gst))
        formData.append("totalPrice", parseFloat(totalPrice))
        formData.append("travelDate", moment(new Date()).format('YYYY-MM-DD'))
        formData.append("noOfApplicants", visaApplicants?.length)
        formData.append("paymentGateway", "RAZORPAY")
        formData.append("paymentGatewayNo", RazorKey)
        formData.append("visaRequestStatus", "SUBMITTED")
        formData.append("paymentStatus", "PAID")
        axios.put(VisaRequestUrls.updateVisaRequest, formData, { ...headerconfig })
            .then((res) => {
                getVisaRequest();
            }).catch(function (error) {
                console.log(error);
            });
    }
    const getVisaRequest = () => {
        axios.get(VisaRequestUrls.getVisaRequestsById + "/" + params.visaRequestId, { ...headerconfig })
            .then((res) => {
                if (res.data.statusCode == 200) {
                    setVisaRequest(res.data.data);
                    if (res.data.data != null) {
                        setGST(visaRequest?.gst); // Round to 2 decimal places
                        setServiceFee(visaRequest?.serviceFee);
                        setNetPrice(visaRequest?.netPrice);
                        setTotalPrice(visaRequest?.totalPrice);
                        setVisaApplicants(res.data.data.visaApplicants)                        
                    }
                }
            })
    }
    const getVisaRequestApplicants = () => {
        axios.get(VisaApplicantUrls.getVisaApplicantsByRequest + "?VisaRequestId=" + params.visaRequestId, { ...headerconfig })
            .then((res) => {
                if (res.data.statusCode == 200) {
                    setVisaApplicants(res.data.data);
                    if (res.data.data != null) {
                        setGST(visaRequest?.gst); // Round to 2 decimal places
                        setServiceFee(visaRequest?.serviceFee);
                        setNetPrice(visaRequest?.netPrice);
                        setTotalPrice(visaRequest?.totalPrice);
                    }
                }
            })
    }
    useEffect(() => {
        getVisaRequest();
        //getVisaRequestApplicants();
    }, []);
    return (
        <SideMenu>
            <div className={classes.va}>
                <div className={classes.va_preview}>
                    <h3 className={classes.va_heading}>Application for {visaRequest?.visaName}</h3>
                    <table className='table'>
                        <tr>
                            <td>Reference No : {visaRequest?.visaReferenceNo}</td>
                            <td>Visa Name : {visaRequest?.visaName}</td>
                            <td>Country : {visaRequest?.countryName}</td>
                            <td>Visa Status : {visaRequest?.visaRequestStatus === "PENDING" ? <span className="badge error">{visaRequest?.visaRequestStatus}</span> : <span className='badge error'>{visaRequest?.visaRequestStatus}</span>}</td>
                        </tr>
                        <tr>
                            <td>Payment Status : {visaRequest?.paymentStatus}</td>
                            <td>Travel Date : {moment(visaRequest?.travelDate).format('DD MMM YYYY')}</td>
                            <td>Price Per Applicant : {visaRequest?.pricePerApplicant} INR</td>
                            <td>Total Price : {visaRequest?.totalPrice} INR</td>
                        </tr>
                        {visaRequest?.visaRequestStatus === "PENDING" ?
                            <tr>
                                <td colSpan={2}>
                                    <div className={classes.va_preview_btns}>
                                        <Link to={"/visarequest/" + params.visaRequestId} className={classes.va_preview_btn}><span className="material-symbols-outlined"></span>Add Applicant</Link>
                                        <button onClick={(e) => handlePayment(e)} className={classes.va_preview_btn}><span className="material-symbols-outlined"></span>Make Payment</button>
                                    </div>
                                </td>
                            </tr>
                            : ""}
                    </table>
                </div>
                {visaApplicants.length > 0 && visaApplicants.map((va, index) =>
                    <div className={classes.va_preview}>
                        <h1 className={classes.va_preview_h}>{va.title} {va.firstName} {va.lastName} Application</h1>
                        <div className={classes.va_preview_images}>
                            <div className={classes.va_preview_imagesDiv}>
                                <img alt="photograph passport-size" src={va.profileSrc} />
                                <p>Photograph of passport size</p>
                            </div>
                            <div className={classes.va_preview_imagesDiv}>
                                <img src={va.passportFrontSrc} alt="photograph passport-size" />
                                <p>Passport front page </p>
                            </div>
                            <div className={classes.va_preview_imagesDiv}>
                                <img src={va.passportBackSrc} alt="photograph passport-size" />
                                <p>Passport back page</p>
                            </div>
                        </div>
                        <div className={classes.va_preview_info}>
                            <div className={classes.va_preview_info_div}>
                                <p>Name</p>
                                <p>{va.title} {va.firstName} {va.lastName}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Date of birth</p>
                                <p>{moment(va.dateOfBirth).format('DD MMM YYYY')}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Passport number</p>
                                <p>{va.passportNumber}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Passport issue date</p>
                                <p>{moment(va.passportIssueDate).format('DD MMM YYYY')}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Passport expiry date</p>
                                <p>{moment(va.passportExpireDate).format('DD MMM YYYY')}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Gender</p>
                                <p>{va.gender}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Nationality</p>
                                <p>{va.nationality}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Passport country</p>
                                <p>{va.passportCountry}</p>
                            </div>
                            <div className={classes.va_preview_info_div}>
                                <p>Traveler category</p>
                                <p>{va.travelerType}</p>
                            </div>
                        </div>
                        <h3>Files Uploaded</h3>
                        {va.visaApplicationDocuments.length > 0 ? (
                            <ul>
                                {va.visaApplicationDocuments.map((file, index) => (
                                    <li key={index}>
                                        {file.imageName}
                                    </li>
                                ))}
                            </ul>
                        ) : ""}
                    </div>
                )}
            </div>
        </SideMenu>
    );
};
export default ApplicationDetails;
