import classes from './Profile.module.css';
import SideMenu from './SideMenu';

const Profile = () => {
  return (
    <SideMenu>
      <div className={classes.profile}>
        <h1 className={classes.profile_head}>Profile</h1>
        <form method="POST" className={classes.profile_applicant_form}>
          <div className={classes.profile_applicant_titleDiv}>
            <div className={classes.profile_applicant_inputDiv}>
              <label>Title</label>
              <select>
                <option>Mr.</option>
                <option>Mrs.</option>
                <option>Ms.</option>
              </select>
            </div>
            <div className={classes.profile_applicant_inputDiv}>
              <label>First name</label>
              <input
                type="text"
                name="firstName"
                placeholder="Please enter first name"
              ></input>
            </div>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Last name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Please enter last name"
            ></input>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Nationality</label>
            <select>
              <option>Indian</option>
              <option>Canadian</option>
              <option>Saudi</option>
            </select>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Mobile number</label>
            <input
              type="text"
              name="mobileNumber"
              placeholder="Please enter mobile no."
            ></input>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Please enter email"
            ></input>
          </div>
          <div className={classes.profile_applicant_inputDiv}>
            <label>&nbsp;</label>
            <button className={classes.profile_applicant_saveBtn}>Save</button>
          </div>
        </form>
      </div>
    </SideMenu>
  );
};
export default Profile;
