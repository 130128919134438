import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import VisaList from './components/visa/VisaList';
import VisaHome from './components/visa/VisaHome';
import ApplicationForm from './components/dashbord/ApplicationForm';
import Applications from './components/dashbord/Applications';
import Profile from './components/dashbord/Profile';
import Favourites from './components/dashbord/Favourites';
import Logout from './components/dashbord/Logout';
import AppLayout from './components/ui/AppLayout';
import Privacy from './components/footer/Privacy';
import About from './components/footer/About';
import Contact from './components/footer/Contact';
import Visa from './components/visa/Visa';
import ApplicationDetails from './components/dashbord/ApplicationDetails';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <VisaHome />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: 'visas/:url?',
        element: <VisaList />,
      },
      {
        path: 'visa/:visaId',
        element: <Visa />,
      },
      {
        path: 'visarequest/:visaRequestId?',
        element: <ApplicationForm />,
      },
      {
        path: 'visaapplicants/:visaRequestId?',
        element: <ApplicationDetails />,
      },
      {
        path: 'visa/applications',
        element: <Applications />,
      },
      {
        path: 'fav',
        element: <Favourites />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};
export default App;
