import { NavLink, useNavigate } from 'react-router-dom';
import classes from './SideMenu.module.css';
import { useEffect } from 'react';

const SideMenu = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('userId') === null) {
      navigate("/");
    }
  },[]);
  return (
    <div className={classes.side}>
      <div className={classes.side_menu}>
        <NavLink to="/" className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#search"></use>
          </svg>
        </NavLink>
        {/* <NavLink to="/visa/new" className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#new"></use>
          </svg>
        </NavLink> */}
        <NavLink to="/visa/applications" className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#stack"></use>
          </svg>
        </NavLink>
        <NavLink to="/fav" className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#heart"></use>
          </svg>
        </NavLink>
        <NavLink to="/profile" className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#person"></use>
          </svg>
        </NavLink>
        <NavLink to="/logout" className={classes.side_menuItem}>
          <svg>
            <use href="/assets/svgs/stripe.svg#logout"></use>
          </svg>
        </NavLink>
      </div>
      <div>{children}</div>
    </div>
  );
};
export default SideMenu;
