import { Link } from 'react-router-dom';
import classes from './Logout.module.css';
import { useEffect } from 'react';
import { useUser } from '../auth/UserContext';
const Logout = () => {
  const { setIsLoggedIn } = useUser();
  useEffect(() => {
    localStorage.clear();
    setIsLoggedIn(true);
}, [])
  return (
    <div className={classes.logout}>
      <img
        className={classes.logout_img}
        src="/assets/images/thumbUp.jpg"
        alt="logout"
      />
      <h1 className={classes.logout_h}>You have successfully logged out...</h1>
      <Link className={classes.logout_redirect} to="/">
        <svg className={classes.logout_searchIcon}>
          <use href="/assets/svgs/stripe.svg#search"></use>
        </svg>
        Search for visas
      </Link>
    </div>
  );
};
export default Logout;
