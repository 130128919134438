import { Link, NavLink, useLocation } from 'react-router-dom';
import classes from './Header.module.css';
import { useEffect, useState } from 'react';
import LoginSignup from '../auth/LoginSignup';
import { useUser } from '../auth/UserContext';

const Header = () => {
  const location = useLocation();
  const { isLoggedIn } = useUser();
  const [homePage, setHomePage] = useState(false);
  const [currPath, setCurrPath] = useState(location.pathname);
  const [showLogin, setShowlogin] = useState(false);
  const loginToggle = () => setShowlogin(!showLogin);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/holiday') {
      setHomePage(true);
    } else {
      setHomePage(false);
    }
    setCurrPath(location.pathname);
  }, [location.pathname]);

  return (
    <header className={classes.header}>
      {showLogin && <LoginSignup toggleView={loginToggle} />}
      <Link to={"/"} style={{ fontSize: "30px", fontWeight: "bold", paddingTop: "10px", paddingLeft: "40px" }}>trawtel.com</Link>
      {/* <img
        className={classes.header_logo}
        onClick={() => window.location.assign('/')}
        src="/assets/images/logo.png"
        alt="logo"
      ></img>
      <img
        className={classes.header_logo2}
        onClick={() => window.location.assign('/')}
        src="/assets/images/logoMini.png"
        alt="logo"
      ></img> */}
      <div className={classes.header_tabs} key={location}>
        <Link
          className={
            currPath?.includes('visa') ? classes.header_tabsActive : location.pathname === '/' ? classes.header_tabsActive : ''
          } to="/">
          Visa
        </Link>
        <Link to="https://b2b.trawtel.com" target='_blank'>Agents</Link>
      </div>
      <div className={classes.header_acc} style={{ fontSize: "30px", fontWeight: "bold", paddingTop: "10px", paddingLeft: "40px" }}>
        <svg className={classes.header_acc_fav}>
          <use href="/assets/svgs/stripe.svg#heartFull"></use>
        </svg>
        {!isLoggedIn ?
          <div className={classes.header_acc_acc} onClick={loginToggle}>
            <svg className={classes.header_acc_acc_svg}>
              <use href="/assets/svgs/stripe.svg#account"></use>
            </svg>
            <span className={classes.header_acc_acc_link}>Login or Signup</span>
          </div>
          :
          <div className={classes.header_acc_acc}>
            <svg className={classes.header_acc_acc_svg}>
              <use href="/assets/svgs/stripe.svg#account"></use>
            </svg>
            <span className={classes.header_acc_acc_link}><Link to={"/profile"}>MyAccount</Link></span>
          </div>
        }
      </div>
    </header>
  );
};
export default Header;
