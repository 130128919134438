import classes from './Contact.module.css';

const Contact = () => {
  return (
    <div className={classes.contact}>
      <h1 className={classes.contact_h}>Contact us</h1>
      <img
        className={classes.contact_img}
        src="/assets/images/pattern.jpg"
        alt="about"
      />
      <div className={classes.contact_data}>
        <div className={classes.contact_info}>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
            odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
            Suspendisse urna nibh viverra non semper suscipit posuere a pede.
          </p>
          <ul>
            <li>help@trawtel.com</li>
            <li>+91 9876543210</li>
          </ul>
          <iframe
            className={classes.contact_info_map}
            title="trawtelAddress"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18109.386736195436!2d78.54913961743556!3d17.41036720997808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97477de19ea3%3A0xbf1563c1b0fdc630!2sDevelopers%20Code!5e0!3m2!1sen!2sin!4v1706161382440!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className={classes.contact_img}>
          <img src="/assets/images/contact.jpg" alt="contact" />
        </div>
      </div>
    </div>
  );
};
export default Contact;
