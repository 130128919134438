import { useNavigate } from 'react-router-dom';
import classes from './LoginSignup.module.css';
import { useState } from 'react';
import { HomeUrls } from '../common/APIConfig';
import axios from 'axios';
import { useUser } from '../auth/UserContext';
const initialLoginValues = {
  phoneNumber: '',
  otp: ''
};
const LoginSignup = ({ toggleView, onLoginSuccess }) => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useUser();
  const [flag, setFlag] = useState('/assets/flags/in.svg');
  const [otpReceived, setOTPReceived] = useState(false);
  const [values, setValues] = useState(initialLoginValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validatesend = () => {
    let temp = {};
    temp.phoneNumber = values.phoneNumber == '' ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x == true);
  };
  const validateverify = () => {
    let temp = {};
    temp.otp = values.otp == '' ? false : true;
    setErrors(temp);
    return Object.values(temp).every((x) => x == true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otpReceived) {
      if (validatesend()) {
        setLoading(true);
        axios
          .post(HomeUrls.sendotp + "?phoneNumber=" + values.phoneNumber)
          .then((response) => {
            if (response.data.statusCode == 200) {
              setOTPReceived(true);
              setLoading(false);
            }
          }).catch(function (error) {
            setLoading(false);
          });

      } else {
        setLoading(false);
        alert('Please enter the mandatory fields');
      }
    }
    else {
      if (validateverify()) {
        setLoading(true);
        axios
          .post(HomeUrls.verifyotp + "?phoneNumber=" + values.phoneNumber + "&otp=" + values.otp)
          .then((response) => {
            if (response.data.statusCode !== 500) {
              //setUserData(response.data)
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('expiration', response.data.expiration);
              localStorage.setItem('userId', response.data.userId);
              setLoading(false);
              toggleView();
              setIsLoggedIn(true);
              onLoginSuccess();
            }
            else {
              alert(response.data.data);
              setLoading(false);
            }
          }).catch(function (error) {
            setLoading(false);
          });

      } else {
        setLoading(false);
        alert('Please enter the mandatory fields');
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
      <div className={classes.auth}>
        <div className={classes.auth_div}>
          <button className={classes.auth_div_close} onClick={() => toggleView()}>
            X
          </button>
          <div className={classes.auth_header}>
            <img src="/assets/images/signup.png" alt="quick signup" />
            <div className={classes.auth_header_text}>
              <p className={classes.auth_header_highlight}>Quick signing</p>
              <p className={classes.auth_header_brief}>
                Just your mobile number and leave rest on us.
              </p>
            </div>
          </div>
          <div className={classes.auth_phone}>
            <div className={classes.auth_phone_div}>
              <div className={classes.auth_phone_div_flag}>
                <img src={flag} alt="flag" />
              </div>
              <input className={classes.auth_phone_div_input} type="number" placeholder="Your number please"
                value={values.phoneNumber} name='phoneNumber' onChange={handleInputChange}
              />
            </div>
            {otpReceived == true ?
              <div className={classes.auth_phone_div}>
                <div className={classes.auth_phone_div_flag}></div>
                <input className={classes.auth_phone_div_input} type="number" placeholder="Enter OTP" value={values.otp} name='otp' onChange={handleInputChange} />
              </div> : ""}
            {loading === false ?
              <button className={classes.auth_phone_btn} type='submit'>Submit</button>
              :
              <button className={classes.auth_phone_btn} type='button' disabled>Loading...</button>
            }
          </div>
        </div>
      </div>
    </form>
  );
};
export default LoginSignup;
