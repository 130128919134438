import { useNavigate, useParams } from 'react-router-dom';
import classes from './VisaList.module.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { VisaUrls } from '../common/APIConfig';

const VisaList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState({});
  const [visas, setVisas] = useState([]);
  const [terms, setTerms] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [faq, setFAQ] = useState([]);
  const headerconfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    }
  }
  const GetVisas = () => {
    axios.get(VisaUrls.getVisaByCountryURL + params.url, { ...headerconfig })
      .then((response) => {
        setCountry(response.data.data)
        if (response.data.data.visas !== undefined || response.data.data.visas !== null) {
          setVisas(response.data.data.visas)
        }
        setTerms(response.data.data.visaTerms)
        setFAQ(response.data.data.visaFAQs)
        setDocuments(response.data.data.visaDocuments)
      })
      .catch(function (error) {
        console.log(error)
      });
  };
  useEffect(() => {
    GetVisas();
  }, []);
  const itemHandle = (e, url) => {
    e.preventDefault();
    navigate('/visas/' + url);
  }
  return (
    <div className={classes.vl}>
      <div className={classes.vl_header}>
        <h1 className={classes.vl_header_title}>{country.name} Visas</h1>
        <div className={classes.vl_header_bar}>
          <div className={classes.vl_header_bar_info}>
            <svg className={classes.vl_header_bar_svg}>
              <use href="/assets/svgs/stripe.svg#timer"></use>
            </svg>
            <p className={classes.vl_header_bar_info_h}>Processing time</p>
            <p className={classes.vl_header_bar_info_b}>Upto 48Hrs</p>
          </div>
          <div
            className={`${classes.vl_header_bar_info} ${classes.vl_header_bar_info2}`}
          >
            <svg className={classes.vl_header_bar_svg}>
              <use href="/assets/svgs/stripe.svg#money"></use>
            </svg>
            <p className={classes.vl_header_bar_info_h}>Starting from</p>
            <p className={classes.vl_header_bar_info_b}>&#8377; 1999/-</p>
          </div>
          <img
            className={classes.vl_header_bar_img}
            src="/assets/images/dubai.jpg"
            alt="Location"
          ></img>
        </div>
      </div>

      <div className={classes.vl_list}>
        {visas.map((item) => (
          <div className={classes.vl_list_item} key={item}>
            <h1 className={classes.vl_list_item_title}>{item.visaName}</h1>
            <ul className={classes.vl_list_item_ul}>
              <li className={classes.vl_list_item_li}>
                <p>Type</p>
                <p>{item.visaType}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Processing time</p>
                <p>{item.processingTime === 1 ? "1 Day" : item.processingTime + " Days"}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Stay period</p>
                <p>{item.stayPeriod}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Validity</p>
                <p>{item.validity}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Entry</p>
                <p>{item.entry}</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Fees</p>
                <p>INR {item.price}/-</p>
              </li>
            </ul>
            <button className={classes.vl_list_item_btn}>Apply</button>
          </div>
        ))}
        <div className={classes.vl_list_item}>
            <h1 className={classes.vl_list_item_title}></h1>
            <ul className={classes.vl_list_item_ul}>
              <li className={classes.vl_list_item_li}>
                <p>Type</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Processing time</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Stay period</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Validity</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Entry</p>
              </li>
              <li className={classes.vl_list_item_li}>
                <p>Fees</p>
              </li>
            </ul>
          </div>
      </div>      
    </div>
  );
};

export default VisaList;
