import classes from './Privacy.module.css';

const Privacy = () => {
  return (
    <>
      <h1 className={classes.privacy_h}>Privacy and FAQs</h1>
      <img
        className={classes.privacy_img}
        src="/assets/images/pattern.jpg"
        alt="about"
      />
      <div className={classes.privacy}>
        <p>Last updated: May 05, 2024</p>
        <p>Welcome to SAAR DEVELOPERS CODE Pvt. Ltd (Trawtel). At Trawtel, we are committed to protecting and respecting your privacy.</p>
        <p>
          This Privacy Policy describes our policies and procedures on the
          collection, use, disclosure, and transfer of Your information by Trawtel
          and/or its parent/subsidiary company and/or affiliate(s) (collectively
          referred to as “Company” or “we” or “us”) when You use our Service
          This Privacy Policy forms part of the Terms of Use for the Services.
          Capitalized terms that have been used here but are undefined shall
          have the same meaning as attributed to them in the Terms of Use.
        </p>
        <p>
          By using the Service, you agree to the collection, storage, and use of
          the Personal Data that you provide (including any changes thereto as
          provided by you) in accordance with this Privacy Policy.
        </p>
        <h1>DEFINITIONS</h1>
        <h2>
          The capitalized terms shall have the meaning as defined under: -
        </h2>
        <ol>
          <li>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </li>
          <li>
            Affiliate means an entity that controls, is controlled by or is
            under common control with a party, where "control" means ownership
            of 50% or more of the shares, equity interest or other securities
            entitled to vote for election of directors or other managing
            authority.
          </li>
          <li>
            Application means the website (“Site”) and the software program
            provided by the Company and accessed or downloaded by You on any
            electronic device, named trawtel.
          </li>
          <li>
            Company (referred to as either "the Company", "We", "Us" or "Our" in
            this Agreement) refers to SAAR DEVELOPERS CODE Private Limited.
          </li>
          <li>Country refers to India.</li>
          <li>
            Device means any device that can access the Service such as a
            computer, a cell phone or a digital tablet.
          </li>
          <li>
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </li>
        </ol>
        <div className={classes.faq}>
          <h1>Frequntly Asked Questions</h1>
          {[1, 2, 3, 4, 5].map((item) => (
            <div className={classes.faq_set} key={item}>
              <h2>Why do I need a travel agent?</h2>
              <div className={classes.faq_set_ps}>
                <p>
                  A good travel agent is a travel expert who understands
                  everything associated with planning and booking the perfect
                  trip. They can give advice on places to go, as well as how
                  much you’ll need to spend and how long to spend there.
                </p>
                <p>
                  There are many reasons to book your trip with Guide Me Away.{' '}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Privacy;
