import classes from './About.module.css';
const About = () => {
  return (
    <div className={classes.about}>
      <h1 className={classes.about_h}>About us</h1>
      <img
        className={classes.about_img}
        src="/assets/images/pattern.jpg"
        alt="about"
      />
      <div className={classes.about_info}>
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
            odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
            Suspendisse urna nibh viverra non semper suscipit posuere a pede.
          </p>
          <p>
            Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae
            luctus metus libero eu augue. Morbi purus libero, faucibus
            adipiscing, commodo quis, gravida id, est. Sed lectus. Praesent
            elementum hendrerit tortor. Sed semper lorem at felis. Vestibulum
            volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu
            pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu,
            fermentum et, dapibus sed, urna.
          </p>
          <p>
            Ut convallis, sem sit amet interdum consectetuer, odio augue aliquam
            leo, nec dapibus tortor nibh sed augue. Integer eu magna sit amet
            metus fermentum posuere. Morbi sit amet nulla sed dolor elementum
            imperdiet. Quisque fermentum. Cum sociis natoque penatibus et magnis
            xdis parturient montes, nascetur ridiculus mus. Pellentesque
            adipiscing eros ut libero. Ut condimentum mi vel tellus. Suspendisse
            laoreet. Fusce ut est sed dolor gravida convallis. Morbi vitae ante.
            Vivamus ultrices luctus nunc. Suspendisse et dolor. Etiam dignissim.
            Proin malesuada adipiscing lacus. Donec metus. Curabitur gravida
          </p>
        </div>
        <div className={classes.about_info_imgDiv}>
          <img
            className={classes.about_info_img}
            src="/assets/images/about2.jpg"
            alt="about travel"
          />
        </div>
      </div>
    </div>
  );
};
export default About;
